import { styled } from 'src/gatsby-theme-stitches/config'

export const Container = styled('div', {
  marginTop: '-3rem',
  width: '100%',
  padding: '0.5rem 1.875rem',
  background: '#E96A6D',
  boxShadow: '0px 0px 50px #00000038',
  position: 'relative',
  zIndex: '1',

  '@onlyMobile': {
    padding: '0.5rem 0.875rem 2.5rem',
  },
})

export const Wrapper = styled('div', {
  position: 'relative',
  boxSizing: 'border-box',
})

export const CenteredWrapper = styled('div', {
  margin: '0 auto',
  width: '85%',

  '@onlyMobile': {
    width: '100%',
  },

  '> h2': {
    position: 'relative',
    bottom: '2.188rem',
    fontFamily: 'Zona Pro, sans-serif',
    fontSize: '2.5rem',
    fontWeight: '900',
    lineHeight: '2.8rem',
    textAlign: 'initial',
    textTransform: 'uppercase',
    textShadow: '0.25rem 0.313rem 0.125rem #00000029',
    color: '#FFF',

    '@media screen and (min-width: 1920px)': {
      position: 'initial',
      textAlign: 'center',
      marginBottom: '2.5rem',
    },

    '@media(max-width: 1024px)': {
      bottom: '5.9375rem',
    },

    '@onlyMobile': {
      bottom: '2.188rem',
      fontSize: '1.5rem',
      lineHeight: '1.625rem',
      textAlign: 'center',
    },
  },
})

export const TrendContainer = styled('div', {
  display: 'flex',
  margin: '-5.4rem 0 0',
  flexDirection: 'row',

  '.image': {
    display: 'block',
    width: '60%',
    marginTop: '1.375rem',

    img: {
      width: '100%',
      objectFit: 'cover',
    },

    '@onlyMobile': {
      display: 'block',
      width: '100%',
    },
  },

  '@media screen and (min-width: 1640px)': {
    margin: '0 auto',
    width: '80%',
  },

  '@media screen and (min-width: 1920px)': {
    margin: '0 auto',
    width: '70%',
  },

  '@onlyMobile': {
    margin: '-1.875rem 0 0',
    flexDirection: 'column',
  },
})

export const TrendContainerText = styled('div', {
  display: 'flex',
  justifyContent: 'space-evenly',
  flexDirection: 'column',
  flex: '1',
  order: 'initial',

  '@onlyMobile': {
    order: '2',
  },

  '.first': {
    marginBottom: '0',
    textAlign: 'center',

    a: {
      color: '#fff',
    },

    p: {
      fontFamily: 'Zona Pro, sans-serif',
      fontSize: '1.375rem',
      fontWeight: '300',
      lineHeight: '1.75rem',
      textAlign: 'initial',
      color: '#FFF',

      '@onlyMobile': {
        fontSize: '.9375rem',
        textAlign: 'center',
        lineHeight: '1.125rem',
      },
    },

    '@onlyMobile': {
      marginBottom: '0.938rem',
      transform: 'initial',
    },
  },

  '.second': {
    p: {
      fontFamily: 'Zona Pro, sans-serif',
      fontSize: '1.375rem',
      fontWeight: '300',
      textAlign: 'initial',
      lineHeight: '1.75rem',
      color: '#FFF',

      '@onlyMobile': {
        fontSize: '0.813rem',
        textAlign: 'center',
        lineHeight: '1.125rem',
      },
    },

    '@onlyMobile': {
      transform: 'initial',
    },
  },
})

export const TrendContainerImage = styled('div', {
  display: 'none',
  width: '62%',
  order: 'initial',

  img: {
    width: '100%',
    objectFit: 'cover',
  },

  '@onlyMobile': {
    display: 'flex',
    width: '100%',
    order: '4',
    marginTop: '1.25rem',
  },
})

export const ShelfContainer = styled('div', {
  marginTop: '1.625rem',

  '@onlyMobile': {
    marginTop: '0',
  },
})

export const ShelfContainerText = styled('div', {
  mt: '5rem',

  h2: {
    fontFamily: 'Zona Pro, sans-serif',
    fontSize: '2.5rem',
    fontWeight: '900',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#FFF',

    '@onlyMobile': {
      fontSize: '1.563rem',
    },
  },
})

export const ShelfContainerTextWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  position: 'relative',
  margin: '0.5rem auto 4rem',
  fontFamily: 'Zona Pro, sans-serif',
  fontSize: '1.25rem',
  lineHeight: '1.063rem',
  color: '#FFF',

  '@media screen and (max-width: 1024px)': {
    flexDirection: 'column',

    p: {
      margin: '0.5rem auto 2rem',
    },
  },

  '@onlyMobile': {
    lineHeight: '1.063rem',
    fontSize: '0.813rem',
    margin: '0.5rem auto 2rem',
    flexDirection: 'column',
  },

  p: {
    fontWeight: '400',
    textAlign: 'center',
    margin: 'initial',
    width: 'initial',

    '@media screen and (min-width: 768px) and (max-width: 1024px)': {
      marginBottom: '1.25rem',
    },

    '@onlyMobile': {
      fontSize: '.9375rem',
      margin: '0 auto 0.75rem',
      width: '70%',
    },
  },
})

export const ShelfContainerLink = styled('div', {
  position: 'absolute',
  right: '6rem',

  '@media screen and (max-width: 1024px)': {
    position: 'initial',
  },

  'div[data-testid="shelfPage"]': {
    '> div': {
      borderRadius: 'unset !important',
    },
  },

  a: {
    display: 'inline',
    fontWeight: '600',
    textDecoration: 'underline',
    textAlign: 'center',
    color: '#FFF',
  },

  '@onlyMobile': {
    fontSize: '.9375rem',
    position: 'initial',
    right: 'initial',
  },
})

export const ShelfComponent = styled('div', {
  margin: '3.125rem 0',

  svg: {
    color: '#FFF',
  },

  '@onlyMobile': {
    margin: '0',
  },
})
