import type { ReactElement } from 'react'
import React from 'react'
import { Image } from '@vtex/store-ui'
import { Link } from 'gatsby'
import { Hidden } from 'src/components/ui/Hidden'
import Shelf from 'src/components/product/ProductSlider'
import ProductSummary from 'src/components/product/ProductSummaryWhite'
import * as Tabs from '@radix-ui/react-tabs'
import useDeviceDetect from 'src/hooks/useDeviceDetect'

import {
  CollectionLeft,
  CollectionRight,
  CollectionWrapper,
  CollectionWrapperText,
  ShelfWrapper,
  Wrapper,
} from './styles'

interface Props {
  collectionItems: Array<{
    id: number
    banner: {
      alt: string
      description: string
      desktop: {
        srcSet: string
      }
      mobile: {
        srcSet: string
      }
      title: string
    }
    description: ReactElement
    imageGraphQl: {
      alt: string
      desktop: {
        srcSet: string
      }
      mobile: {
        srcSet: string
      }
    }
    shelf: {
      description: string
      searchParams: {
        hideUnavailableItems: false
        orderBy: string
        from: number
        to: number
        collection: string
      }
      title: string
      url: string
    }
    name: string
    title: ReactElement
  }>
}

const TabsContent = ({ collectionItems }: Props) => {
  const { isMobile } = useDeviceDetect()

  return (
    <div>
      {collectionItems.map((item) => (
        <Tabs.Content value={`tab${item.id}`} key={item.id}>
          <CollectionWrapper>
            <CollectionLeft>
              <CollectionWrapperText>
                {item.title}
                <Wrapper>
                  {item.description}
                  <Hidden device="mobile">
                    <div className="link">
                      <Link to={item.shelf.url}>Ver todos </Link>
                    </div>
                  </Hidden>
                  <Hidden device="desktop">
                    <div className="link">
                      <Link to={item.shelf.url}>Ver todos </Link>
                    </div>
                  </Hidden>
                </Wrapper>
              </CollectionWrapperText>
              <ShelfWrapper>
                <Shelf
                  showArrows
                  ProductSummary={ProductSummary}
                  searchParams={item.shelf.searchParams}
                  pageSizes={[1, 1, 2]}
                />
              </ShelfWrapper>
            </CollectionLeft>
            <CollectionRight>
              <Image
                src={
                  isMobile
                    ? item.banner.mobile.srcSet
                    : item.banner.desktop.srcSet
                }
                alt={item.banner.alt}
                title={item.banner.alt}
              />
            </CollectionRight>
          </CollectionWrapper>
        </Tabs.Content>
      ))}
    </div>
  )
}

export default TabsContent
