import { styled } from 'src/gatsby-theme-stitches/config'

export const CollectionWrapper = styled('div', {
  display: 'flex',
  gap: '1.875rem',
  flexDirection: 'row',
  margin: '2rem auto 0',
  width: '88%',

  '@media screen and (min-width: 768px) and (max-width: 1024px)': {
    flexDirection: 'column',
  },

  '@onlyMobile': {
    flexDirection: 'column',
    margin: '1.875rem auto 0',
    width: '100%',
  },
})

export const CollectionLeft = styled('div', {
  order: 'initial',
  flex: '1',

  '@media screen and (min-width: 768px) and (max-width: 1024px)': {
    order: '1',
  },

  '@onlyMobile': {
    order: 2,
  },
})

export const CollectionWrapperText = styled('div', {
  margin: 'initial',
  width: '90%',

  '@media screen and (min-width: 768px) and (max-width: 1024px)': {
    width: '100%',
  },

  '@onlyMobile': {
    margin: '0 auto',
  },

  h3: {
    fontFamily: 'Zona Pro, sans-serif',
    fontSize: '1.8rem',
    fontWeight: '900',
    lineHeight: '2rem',
    textTransform: 'uppercase',
    textAlign: 'initial',
    color: '#FFF',

    '@media screen and (min-width: 768px) and (max-width: 1024px)': {
      textAlign: 'center',
    },

    strong: {
      fontWeight: '900',
      color: '#EAC239',
    },

    '@onlyMobile': {
      fontSize: '1.313rem',
      lineHeight: '1.5rem',
      textAlign: 'center',
    },
  },

  p: {
    margin: '0 0 1.625rem',
    display: 'initial',
    fontFamily: 'Zona Pro, sans-serif',
    fontSize: '1rem',
    fontWeight: '400',
    textAlign: 'initial',
    lineHeight: '1.313rem',
    color: '#FFF',

    strong: {
      fontWeight: '700',
    },

    '@onlyMobile': {
      margin: '0.875rem 0 0.7rem',
      display: 'block',
      fontSize: '0.875rem',
      textAlign: 'center',
      lineHeight: '1.188rem',
    },
  },

  a: {
    display: 'initial',
    justifyContent: 'center',
    marginLeft: '1rem',
    marginRight: '0.25rem',
    width: 'initial',
    fontFamily: 'Zona Pro, sans-serif',
    fontSize: '1rem',
    fontWeight: '600',
    textAlign: 'initial',
    textDecoration: 'underline',
    lineHeight: '1.313rem',
    color: '#FFF',

    '@onlyMobile': {
      display: 'flex',
      marginLeft: 'initial',
      fontSize: '0.875rem',
      textAlign: 'center',
      lineHeight: '1.188rem',
    },
  },
})

export const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '64%',
  margin: '6px 0 0',
  fontFamily: 'Zona Pro, sans-serif',
  fontSize: '1rem',
  fontWeight: '400',
  lineHeight: '1.313rem',
  color: '#FFF',

  '@media screen and (min-width: 768px) and (max-width: 1024px)': {
    margin: '1rem auto 0',
    width: '72%',
  },

  '@onlyMobile': {
    width: '100%',
    margin: 'initial',
    fontSize: '0.875rem',
    lineHeight: '1.188rem',
    gap: '.625rem',
  },

  '.link': {
    display: 'flex',
    justifyContent: 'initial',

    a: {
      border: '2px solid #fff',
      backgroundColor: 'transparent',
      padding: '.6875rem 4.375rem',
      textDecoration: 'none',
      fontSize: '1.375rem',
      borderRadius: '50px',
      marginLeft: '0',
      marginRight: '0',
      fontWeight: '400',

      '&:hover': {
        backgroundColor: '#fff',
        color: '#C63034',
        fontWeight: '600',
        letterSpacing: '-.2px',
      },
    },

    '@onlyMobile': {
      width: '100%',
      justifyContent: 'center',

      a: {
        fontSize: '1rem',
        padding: '.4375rem 2.5rem',
      },
    },
  },
})

export const ShelfWrapper = styled('div', {
  margin: '2rem 0 0 -2.625rem',

  '@media screen and (min-width: 768px) and (max-width: 1024px)': {
    margin: '2.125rem auto 0',
    width: '75%',
  },

  svg: {
    color: ['#FFF'],
  },

  '@onlyMobile': {
    margin: '1.5rem 0 0 0',
  },
})

export const CollectionRight = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  width: '40%',
  order: 'initial',

  '@media screen and (min-width: 768px) and (max-width: 1024px)': {
    margin: '0 auto',
    width: '70%',

    img: {
      width: '100%',
    },
  },

  img: {
    width: '100%',
  },

  '@onlyMobile': {
    width: '100%',
    order: '1',
  },
})
