import React, { useState } from 'react'
import { Image } from '@vtex/store-ui'
import * as Tabs from '@radix-ui/react-tabs'
import { ArrowDownIcon, ArrowLeft } from 'src/components/icons'
import useDeviceDetect from 'src/hooks/useDeviceDetect'

import TabsContent from '../TabsContent'
import {
  CollectionBox,
  CollectionContentControls,
  CollectionsContainer,
  CollectionsContent,
  CollectionsContentText,
  ControlledContainer,
  FirstBlockMainContainer,
  FirstBlockWrapper,
} from './styles'

interface Props {
  data: Array<{
    props: {
      alt: string
      description: string
      desktop: {
        srcSet: string
      }
      mobile: {
        srcSet: string
      }
      title: string
    }
  }>
}

const FirstBlock = ({ data }: Props) => {
  const [current, setCurrent] = useState<number>(0)
  const [isMinnieWalletOpen, setIsMinnieWalletOpen] = useState(true)

  const { isMobile } = useDeviceDetect()

  const collectionItems = [
    {
      id: 1,
      imageGraphQl: isMinnieWalletOpen ? data[1].props : data[2].props,
      banner: data[9].props,
      shelf: data[8].props,
      firstBadge: 'LANÇAMENTO',
      secondBadge: 'EDIÇÃO LIMITADA',
      name: 'Coleção Minnie',
      title: (
        <h3>
          Para viver a magia
          <br />
          <strong>da maquiagem</strong>
        </h3>
      ),
      description: (
        <p>
          Explore e se encante com o mundo mágico de makes e esmaltes, e
          diversos outros produtos da Minnie Mouse. Um show de cores e efeitos
          que você nunca viu, nem nas telinhas!
        </p>
      ),
    },
    {
      id: 2,
      imageGraphQl: data[3].props,
      banner: data[11].props,
      shelf: data[10].props,
      firstBadge: '',
      secondBadge: '',
      name: 'Rosto',
      title: (
        <h3>
          Acabamento incrível,
          <br />
          <strong>sem cara de reboco</strong>
        </h3>
      ),
      description: (
        <p>
          <strong>Variedade de produtos para uniformizar</strong> e contornar o
          rosto sem fechar os poros da pele, deixando com um aspecto natural.
        </p>
      ),
    },
    {
      id: 3,
      imageGraphQl: data[4].props,
      banner: data[13].props,
      shelf: data[12].props,
      firstBadge: '',
      secondBadge: '',
      name: 'Máscaras',
      title: (
        <h3>
          Uma máscara
          <br />
          <strong>para cada look</strong>
        </h3>
      ),
      description: (
        <p>
          Do efeito <strong>natural para modelar</strong> cílios e sobrancelhas,
          ao alongamento ou <strong>volumão para impactar!</strong>
        </p>
      ),
    },
    {
      id: 4,
      imageGraphQl: data[5].props,
      banner: data[15].props,
      shelf: data[14].props,
      firstBadge: '',
      secondBadge: '',
      name: 'Delineadores',
      title: (
        <h3>
          Delineador simples
          <br />
          <strong>ou diferentão?</strong>
        </h3>
      ),
      description: (
        <p>
          Os <strong>delineadores Color Trend</strong> possuem diversas cores,
          indo do matte ao metálico. Com pincel ou em lápis para você escolher
          seu preferido!
        </p>
      ),
    },
    {
      id: 5,
      imageGraphQl: data[6].props,
      banner: data[17].props,
      shelf: data[16].props,
      firstBadge: '',
      secondBadge: '',
      name: 'Boca',
      title: (
        <h3>
          Diferentes acabamentos
          <br />
          para um <strong>bocão do seu jeito</strong>
        </h3>
      ),
      description: (
        <p>
          Batom líquido, em bala, além de hidratantes labiais incríveis para
          você <strong>inovar na make</strong> e <strong>ousar</strong> com
          diferentes cores.
        </p>
      ),
    },
    {
      id: 6,
      imageGraphQl: data[7].props,
      banner: data[19].props,
      shelf: data[18].props,
      firstBadge: '',
      secondBadge: '',
      name: 'Esmaltes',
      title: (
        <h3>
          Unhas coloridas
          <br />
          <strong>é a minha cara!</strong>
        </h3>
      ),
      description: (
        <p>
          <strong>Mais de 30</strong> cores com acabamentos holográficos, com
          cheirinho, cremosos ou cintilantes. Se jogue na nail art Color Trend e
          garanta <strong>um look #trend!</strong>
        </p>
      ),
    },
  ]

  const handleOpenMinnieWalletImage = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    if (id === 1 && e.currentTarget.getAttribute('data-state') !== 'active') {
      setIsMinnieWalletOpen(!isMinnieWalletOpen)
    }
  }

  const handleCheckWinnieWalletState = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    if (id === 1 && e.currentTarget.getAttribute('data-state') === 'active') {
      setIsMinnieWalletOpen(true)
    } else {
      setIsMinnieWalletOpen(false)
    }
  }

  const prevSlide = () => {
    setCurrent((state) =>
      state === 0 ? collectionItems.length - 1 : state - 1
    )
  }

  const nextSlide = () => {
    setCurrent((state) =>
      state === collectionItems.length - 1 ? 0 : state + 1
    )
  }

  return (
    <FirstBlockMainContainer>
      <FirstBlockWrapper>
        <CollectionsContainer>
          <CollectionsContent>
            <CollectionsContentText>
              <h2>
                Tudo que você precisa para montar um{' '}
                <strong>look super colorido</strong> e{' '}
                <strong>ligado às tendências!</strong>
              </h2>
            </CollectionsContentText>

            <Tabs.Root defaultValue="tab1">
              <CollectionContentControls>
                <Tabs.List>
                  <Tabs.Trigger
                    value={current + 1 === 1 ? 'tab6' : `tab${current}`}
                    data-input="prev"
                    role="button"
                    onClick={prevSlide}
                  >
                    <ArrowLeft />
                  </Tabs.Trigger>

                  <Tabs.Trigger
                    value={current + 1 === 6 ? 'tab1' : `tab${current + 2}`}
                    data-input="next"
                    role="button"
                    onClick={nextSlide}
                  >
                    <ArrowLeft />
                  </Tabs.Trigger>
                </Tabs.List>
                <Tabs.List aria-label="Choose your collection">
                  {collectionItems.map((item, index: number) => (
                    <div
                      key={index}
                      className={index === current ? 'active' : 'inactive'}
                    >
                      <Tabs.Trigger
                        value={`tab${item.id}`}
                        style={{
                          border: 'none',
                          outline: 'none',
                          background: 'none',
                          cursor: 'pointer',
                        }}
                      >
                        <CollectionBox>
                          {item.firstBadge && item.secondBadge && (
                            <>
                              <div className="firstBadge">
                                <span>{item.firstBadge}</span>
                              </div>
                              <div className="secondBadge">
                                <span>{item.secondBadge}</span>
                              </div>
                            </>
                          )}
                          <picture>
                            <Image
                              src={
                                isMobile
                                  ? item.imageGraphQl.mobile.srcSet
                                  : item.imageGraphQl.desktop.srcSet
                              }
                              alt={item.imageGraphQl.alt}
                              title={item.imageGraphQl.alt}
                            />
                          </picture>
                          <strong>{item.name}</strong>
                          <span>
                            <ArrowDownIcon />
                          </span>
                        </CollectionBox>
                      </Tabs.Trigger>
                    </div>
                  ))}
                </Tabs.List>
              </CollectionContentControls>

              <ControlledContainer>
                <Tabs.List aria-label="Choose your collection">
                  {collectionItems.map((item, index: number) => (
                    <div
                      className={index === current ? 'active' : 'inactive'}
                      key={index}
                    >
                      <Tabs.Trigger
                        className={item.id === 1 ? 'minnie-wallet' : ''}
                        onMouseEnter={(e) => {
                          handleOpenMinnieWalletImage(e, item.id)
                        }}
                        onMouseLeave={(e) => {
                          handleOpenMinnieWalletImage(e, item.id)
                        }}
                        onClick={(e) => {
                          handleCheckWinnieWalletState(e, item.id)
                        }}
                        value={`tab${item.id}`}
                        style={{
                          border: 'none',
                          outline: 'none',
                          background: 'none',
                          cursor: 'pointer',
                        }}
                      >
                        <CollectionBox>
                          {item.firstBadge && item.secondBadge && (
                            <>
                              <div className="firstBadge">
                                <span>{item.firstBadge}</span>
                              </div>
                              <div className="secondBadge">
                                <span>{item.secondBadge}</span>
                              </div>
                            </>
                          )}
                          <picture>
                            <Image
                              src={
                                isMobile
                                  ? item.imageGraphQl.mobile.srcSet
                                  : item.imageGraphQl.desktop.srcSet
                              }
                              alt={item.imageGraphQl.alt}
                              title={item.imageGraphQl.alt}
                            />
                          </picture>
                          <strong>{item.name}</strong>
                          <span>
                            <ArrowDownIcon />
                          </span>
                        </CollectionBox>
                      </Tabs.Trigger>
                    </div>
                  ))}
                </Tabs.List>
              </ControlledContainer>

              <TabsContent collectionItems={collectionItems} />
            </Tabs.Root>
          </CollectionsContent>
        </CollectionsContainer>
      </FirstBlockWrapper>
    </FirstBlockMainContainer>
  )
}

export default FirstBlock
