import React, { lazy } from 'react'
import type { FC } from 'react'
import type { PageProps as GatsbyPageProps } from 'gatsby'
import { graphql } from 'gatsby'
import Layout from 'src/components/Layout'
import { View } from 'src/components/ui/View'
import Seo from 'src/views/color-trend-hub/Seo'
import AboveTheFold from 'src/views/color-trend-hub/AboveTheFold'

import type { ColorTrendHubQueryQuery } from './__generated__/ColorTrendHubQuery.graphql'

const loader = () => import('src/views/color-trend-hub/BelowTheFold')
const BelowTheFold = lazy(loader)

export type Props = GatsbyPageProps<ColorTrendHubQueryQuery>

const ViewComponents = {
  seo: Seo,
  above: AboveTheFold,
  below: {
    component: BelowTheFold,
    preloader: loader,
  },
}

const Page: FC<Props> = (props) => (
  <Layout>
    <View {...ViewComponents} data={props} />
  </Layout>
)

export const querySSG = graphql`
  query ColorTrendHubQuery {
    cmsInstitutionalPage(name: { eq: "Color Trend Hub" }) {
      sections {
        name
        props: data
      }
      seo {
        siteMetadataWithSlug {
          description
          slug
          title
          titleTemplate
        }
      }
    }
  }
`

export default Page
