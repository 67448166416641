import React from 'react'
import { Image } from '@vtex/store-ui'
import { Link } from 'gatsby'
import { Hidden } from 'src/components/ui/Hidden'
import Shelf from 'src/components/product/ProductSlider'
import ProductSummary from 'src/components/product/ProductSummaryWhite'
import useDeviceDetect from 'src/hooks/useDeviceDetect'

import {
  CenteredWrapper,
  Container,
  ShelfComponent,
  ShelfContainer,
  ShelfContainerLink,
  ShelfContainerText,
  ShelfContainerTextWrapper,
  TrendContainer,
  TrendContainerImage,
  TrendContainerText,
  Wrapper,
} from './styles'

interface Props {
  data: Array<{
    props: {
      alt: string
      desktop: {
        srcSet: string
      }
      mobile: {
        srcSet: string
      }
      searchParams: {
        collection: string
        from: number
        hideUnavailableItems: boolean
        orderBy: string
        to: number
      }
    }
  }>
}

const SecondBlock = ({ data }: Props) => {
  const { isMobile } = useDeviceDetect()

  return (
    <Container>
      <Wrapper>
        <Hidden device="mobile">
          <CenteredWrapper>
            <h2>
              Color Trend ON: <br />
              Se liga nas trends!
            </h2>
            <TrendContainer>
              <TrendContainerText>
                <div className="first">
                  <p>
                    É tendência atrás de tendência. Dessa vez, Color Trend
                    trouxe o mundo mágico da Minnie Mouse e já está encantando
                    diversas pessoas por ai!
                  </p>
                </div>
                <div className="second">
                  <p>
                    Paleta de sombras, delineador, pó compacto, máscara para
                    cílios, batom e esmaltes super especiais. Um show de cores e
                    efeitos que vão te despertar várias ideias.
                  </p>
                </div>
              </TrendContainerText>
              <div className="image">
                <Image
                  src={
                    isMobile
                      ? data[20].props.mobile.srcSet
                      : data[20].props.desktop.srcSet
                  }
                  alt={data[20].props.alt}
                  title={data[20].props.alt}
                />
              </div>
            </TrendContainer>
          </CenteredWrapper>
        </Hidden>

        <Hidden device="desktop">
          <CenteredWrapper>
            <h2>
              Color Trend ON: <br />
              Se liga nas trends!
            </h2>

            <TrendContainer>
              <div className="image">
                <Image
                  src={data[20].props.mobile.srcSet}
                  alt={data[20].props.alt}
                  title={data[20].props.alt}
                />
              </div>
              <TrendContainerText>
                <div className="first">
                  <p>
                    É tendência atrás de tendência. Dessa vez, Color Trend
                    trouxe o mundo mágico da Minnie Mouse
                  </p>

                  <a href="/">Veja mais {'>'}</a>
                </div>
              </TrendContainerText>
              <TrendContainerImage>
                <Image
                  src={data[21].props.desktop.srcSet}
                  alt={data[21].props.alt}
                  title={data[21].props.alt}
                />
              </TrendContainerImage>
            </TrendContainer>
          </CenteredWrapper>
        </Hidden>
        <ShelfContainer>
          <ShelfContainerText>
            <h2>{data[23]?.props?.title}</h2>
            <ShelfContainerTextWrapper>
              <p>
                Vem garantir seus produtos da <strong>Coleção Minnie!</strong>
              </p>
              <ShelfContainerLink>
                <Link to={data[23]?.props?.url}>Ver mais {'>'}</Link>
              </ShelfContainerLink>
            </ShelfContainerTextWrapper>
          </ShelfContainerText>
          <ShelfComponent>
            <Shelf
              searchParams={data[23].props.searchParams}
              showArrows
              ProductSummary={ProductSummary}
              pageSizes={[1, 2, 4]}
            />
          </ShelfComponent>
        </ShelfContainer>
      </Wrapper>
    </Container>
  )
}

export default SecondBlock
