import React from 'react'
import type { FC } from 'react'
import type { PageProps } from 'gatsby'
import { Box } from '@vtex/store-ui'
import EventPageView from 'src/components/common/EventPageView'
import PageBanner from 'src/components/common/PageBanner'
import FirstBlock from 'src/components/color-trend-hub/above/FirstBlock'
import SecondBlock from 'src/components/color-trend-hub/above/SecondBlock'
import type { ColorTrendHubQueryQuery } from 'src/pages/color-trend/__generated__/ColorTrendHubQuery.graphql'

type Props = PageProps<ColorTrendHubQueryQuery>

const Above: FC<Props> = ({ data: { cmsInstitutionalPage } }) => {
  return (
    <Box>
      <EventPageView
        type="OTHER"
        title={cmsInstitutionalPage?.seo?.siteMetadataWithSlug?.title}
      />
      <PageBanner
        {...cmsInstitutionalPage?.sections[0].props}
        heightInline={['260px', 'auto']}
        heightBanner={{ height: 550, width: 1920 }}
        plLandingPage
        sx={{ paddingBottom: '0' }}
      />
      <FirstBlock data={cmsInstitutionalPage?.sections} />
      <SecondBlock data={cmsInstitutionalPage?.sections} />
    </Box>
  )
}

export default Above
