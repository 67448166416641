import { styled } from 'src/gatsby-theme-stitches/config'

export const FirstBlockMainContainer = styled('div', {
  width: '100%',
  padding: '1.875rem',
  background: '#231F20',

  '@onlyMobile': {
    padding: '.625rem',
  },
})

export const FirstBlockWrapper = styled('div', {
  width: ' 100%',
  background: '#C63034',
  padding: '0.5rem 1.25rem 9rem',
  margin: '0 auto',

  '@lg': {
    width: ' 100%',
  },

  '@onlyMobile': {
    padding: ' 0.5rem 0 7.25rem',
  },
})

export const CollectionsContainer = styled('div', {
  position: 'relative',
})

export const CollectionsContent = styled('div', {
  "div[role='tablist']": {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4rem',

    '@onlyMobile': {
      gap: '2.813rem',
    },

    '@media screen and (min-width: 768px) and (max-width: 1024px)': {
      gap: '2rem',
    },

    '.minnie-wallet': {
      background: 'none',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',

      '&:hover': {
        picture: {
          border: '.375rem solid #E96A6D',

          img: {
            transition: 'all .32s',
            transform: 'rotate(-24deg) scale(1.2) !important',
          },
        },

        strong: {
          opacity: '1',
          fontWeight: '900',
        },
      },
    },

    ".minnie-wallet[data-state='active']": {
      picture: {
        border: '.375rem solid #E96A6D',

        img: {
          transition: 'all .32s',
          transform: 'rotate(-24deg) scale(1.2) !important',
        },
      },

      strong: {
        opacity: '1',
        fontWeight: '900',
      },

      span: {
        svg: {
          display: 'block',
          width: '1.25rem',
          height: '1.25rem',
          fill: '#FFF',
        },
      },
    },

    button: {
      background: 'none',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',

      '&:hover': {
        picture: {
          border: '.375rem solid #E96A6D',

          img: {
            transition: 'all .32s ease-in-out',
            transform: 'rotate(24deg) scale(1.2)',
          },
        },

        strong: {
          opacity: '1',
          fontWeight: '900',
        },
      },
    },

    "button[data-state='active']": {
      picture: {
        border: '.375rem solid #E96A6D',
        transition: 'all .32s ease-in-out',

        img: {
          transition: 'all .32s ease-in-out',
          transform: 'rotate(24deg) scale(1.2)',
        },
      },

      strong: {
        opacity: '1',
        fontWeight: '900',
      },

      span: {
        svg: {
          display: 'block',
          width: '1.25rem',
          height: '1.25rem',
          fill: '#FFF',
        },
      },
    },
  },
})

export const CollectionsContentText = styled('div', {
  width: '40.625rem',
  margin: '2.25rem auto 1.75rem',
  textAlign: 'center',

  '@onlyMobile': {
    width: '70%',
    margin: '0.5rem auto 2.25rem',
  },

  h2: {
    fontFamily: 'Zona Pro, sans-serif',
    fontSize: '1.8125rem',
    fontWeight: '300',
    lineHeight: '2.1875rem',
    color: '#FFF',

    strong: {
      fontWeight: '900',
    },

    '@onlyMobile': {
      fontSize: '1rem',
      lineHeight: '1.438rem',
    },
  },

  p: {
    margin: '0 auto',
    width: '58%',
    fontFamily: 'Zona Pro, sans-serif',
    fontSize: '1.125rem',
    fontWeight: '400',
    textAlign: 'center',
    lineHeight: '1.125rem',
    color: '#FFF',

    strong: {
      fontWeight: 900,
    },

    '@onlyMobile': {
      width: '100%',
      margin: '1.375rem auto 0',
      fontSize: '0.938rem',
    },
  },
})

export const CollectionContentControls = styled('div', {
  display: 'none',
  position: 'relative',

  '@onlyMobile': {
    display: 'block',
  },

  "button[data-input='prev']": {
    position: 'absolute',
    top: '3.75rem',
    left: '11%',

    svg: {
      color: '#FFF',
    },
  },

  "button[data-input='next']": {
    position: 'absolute',
    top: '3.75rem',
    right: '11%',
    transform: 'rotate(180deg)',

    svg: {
      color: '#FFF',
    },
  },

  '.active': {
    display: 'block',
    transition: 'all .32s',
  },

  '.inactive': {
    display: 'none',
  },
})

export const CollectionBox = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontFamily: 'Zona Pro, sans-serif',
  position: 'relative',

  '.firstBadge': {
    padding: '.25rem .625rem',
    fontWeight: '900',
    position: 'absolute',
    alignSelf: 'start',
    backgroundColor: 'transparent',
    border: '1px solid #fff',
    color: '#fff',
    fontSize: '.4375rem',
    top: '-37px',
    left: '-25px',
    zIndex: '9',

    '@onlyMobile': {
      zIndex: '1',
      top: '-23px',
    },
  },

  '.secondBadge': {
    padding: '.25rem .75rem',
    fontWeight: '900',
    position: 'absolute',
    alignSelf: 'start',
    backgroundColor: '#231F20',
    fontSize: '.5625rem',
    color: '#fff',
    top: '-15px',
    left: '-25px',
    zIndex: '9',

    '@onlyMobile': {
      zIndex: '1',
      top: '-3px',
    },
  },

  picture: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '6.75rem',
    height: '6.75rem',
    border: '0.5rem solid #FFF',
    borderRadius: '1.25rem',
    padding: '0.5rem',
    background: ' #FFF',

    '@media screen and (min-width: 768px) and (max-width: 1024px)': {
      width: '6rem',
      height: '6rem',
    },

    img: {
      margin: '0 auto',
      height: '160px',
      objectFit: 'cover',
      overflow: 'initial',
    },
  },

  strong: {
    marginTop: '1.2rem',
    fontFamily: 'Zona Pro, sans-serif',
    fontSize: '1.125rem',
    fontWeight: '300',
    color: '#FFF',
    opacity: '.6',
  },

  span: {
    margin: '0.125rem auto 0',
    width: '1.25rem',
    height: '1.25rem',

    svg: {
      display: 'none',
    },
  },
})

export const ControlledContainer = styled('div', {
  display: 'block',

  '@onlyMobile': { display: 'none' },
})
